<template>
  <div class="tour-passport__added-item">
    <div class="tour-passport__added-passport before-none">
      <img src="/assets/img/icons/camera-alt-light.svg" class="tour-passport-image" />
      <button class="button tour-passport__added-pencil">
        <svg
          class="tour-passport__svg-small"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="#000"
        >
          <path
            d="M19.268,2.2,17.8.733a2.5,2.5,0,0,0-3.536,0L.5,14.5l-.5,4.46a.938.938,0,0,0,1.035,1.035l4.46-.5L19.268,5.733a2.5,2.5,0,0,0,0-3.536ZM15.55,7.684,6.8,16.436V15.079H4.922V13.2H3.565l8.752-8.752L15.55,7.684ZM4.928,18.305l-2.3.256L1.44,17.378l.256-2.3.776-.776H3.829v1.875H5.7V17.53ZM18.384,4.849,16.434,6.8,13.2,3.568l1.951-1.951a1.249,1.249,0,0,1,1.768,0l1.464,1.464A1.249,1.249,0,0,1,18.384,4.849Z"
            transform="translate(-0.001 -0.001)"
          />
        </svg>
      </button>
      <button class="button tour-passport__added-trash">
        <svg
          class="tour-passport__svg-small"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="20"
          viewBox="0 0 18 20"
          fill="#000"
        >
          <path
            d="M17.188,2.5H13.125L11.812.75a1.875,1.875,0,0,0-1.5-.75H7.188a1.875,1.875,0,0,0-1.5.75L4.375,2.5H.313A.313.313,0,0,0,0,2.813v.625a.312.312,0,0,0,.313.312h.738l1.3,14.543A1.875,1.875,0,0,0,4.215,20h9.07a1.875,1.875,0,0,0,1.867-1.707l1.3-14.543h.738a.313.313,0,0,0,.313-.312V2.813A.313.313,0,0,0,17.188,2.5Zm-10.5-1a.629.629,0,0,1,.5-.25h3.125a.629.629,0,0,1,.5.25l.75,1H5.937Zm7.219,16.68a.621.621,0,0,1-.621.57H4.215a.621.621,0,0,1-.621-.57L2.3,3.75H15.2Z"
          />
        </svg>
      </button>
    </div>
    <p class="text-center p14">{{ $t("Passenger") }} 1</p>
  </div>
</template>

<script>
export default {
  name: "TourPassport"
}
</script>